exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ae-reporting-js": () => import("./../../../src/pages/ae-reporting.js" /* webpackChunkName: "component---src-pages-ae-reporting-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-how-qarziba-works-how-immunotherapy-works-js": () => import("./../../../src/pages/how-QARZIBA-works/how-immunotherapy-works.js" /* webpackChunkName: "component---src-pages-how-qarziba-works-how-immunotherapy-works-js" */),
  "component---src-pages-how-qarziba-works-qarzibas-mechanism-of-action-js": () => import("./../../../src/pages/how-QARZIBA-works/QARZIBAS-mechanism-of-action.js" /* webpackChunkName: "component---src-pages-how-qarziba-works-qarzibas-mechanism-of-action-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-statement-js": () => import("./../../../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-qarziba-efficacy-and-safety-profile-efficacy-data-js": () => import("./../../../src/pages/QARZIBA-efficacy-and-safety-profile/efficacy-data.js" /* webpackChunkName: "component---src-pages-qarziba-efficacy-and-safety-profile-efficacy-data-js" */),
  "component---src-pages-qarziba-efficacy-and-safety-profile-safety-profile-js": () => import("./../../../src/pages/QARZIBA-efficacy-and-safety-profile/safety-profile.js" /* webpackChunkName: "component---src-pages-qarziba-efficacy-and-safety-profile-safety-profile-js" */),
  "component---src-pages-resources-dosing-calculator-js": () => import("./../../../src/pages/resources/dosing-calculator.js" /* webpackChunkName: "component---src-pages-resources-dosing-calculator-js" */),
  "component---src-pages-resources-infusion-and-administration-js": () => import("./../../../src/pages/resources/infusion-and-administration.js" /* webpackChunkName: "component---src-pages-resources-infusion-and-administration-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-the-qarziba-story-neuroblastoma-and-the-unmet-need-js": () => import("./../../../src/pages/the-QARZIBA-story/neuroblastoma-and-the-unmet-need.js" /* webpackChunkName: "component---src-pages-the-qarziba-story-neuroblastoma-and-the-unmet-need-js" */),
  "component---src-pages-the-qarziba-story-qarziba-heritage-js": () => import("./../../../src/pages/the-QARZIBA-story/qarziba-heritage.js" /* webpackChunkName: "component---src-pages-the-qarziba-story-qarziba-heritage-js" */),
  "component---src-pages-the-qarziba-story-the-qarziba-indication-js": () => import("./../../../src/pages/the-QARZIBA-story/the-qarziba-indication.js" /* webpackChunkName: "component---src-pages-the-qarziba-story-the-qarziba-indication-js" */)
}

